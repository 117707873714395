import { ReactReduxContext as context } from "react-redux"
import Loading from "./components/Loading"
import { Router } from "react-router-dom"
import history from "./history"
import { PersistGate } from "redux-persist/lib/integration/react"
import BootLoader from "./components/BootLoader"
import AppRoutes from "./components/AppRoutes"
import { Provider } from "react-intl-redux"
import useEncryptionGate from "./services/encryption/useEncryptionGate"
import { getEncryptionKey } from "./services/encryption/getEncryptionKey"
import { configureStore } from "./store/configurateStore"

export const Root = () => {
  const encryptionKey = useEncryptionGate({getEncryptionKey});

  if (!encryptionKey) {
    return null;
  }
  const {store, persistor} = configureStore({encryptionKey})

  return (
    <Provider store={store} context={context}>
      <PersistGate persistor={persistor} loading={<Loading />}>
        <Router history={history}>
          <BootLoader>
            <AppRoutes history={history} />
          </BootLoader>
        </Router>
      </PersistGate>
    </Provider>
  )
}
