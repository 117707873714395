import {encryptTransform} from 'redux-persist-transform-encrypt';

let cacheCleared = false

// 🗝️ encrypts data; to best prevent sniffing of user data by browser plugins, etc.
export const createEncryptionTransform = (secretKey) =>
  encryptTransform({
    secretKey,
    onError: function (error) {
      console.error("Decryption failed:", error)

      if (cacheCleared === false && window.persistor) {
        window.persistor.purge()
        cacheCleared = true
      }
    },
  })
