import { Sha256 } from "@aws-crypto/sha256-browser"
import { Buffer } from "buffer"

export type TEncryptionKey = {
  isFresh: boolean;
  key: string | null;
}

const message = process.env.VERSION + "mySaltString";

export const getEncryptionKey = async () => {
  const hash = new Sha256();
  hash.update(message);

  const uint8 = await hash.digest();

  return {
    isFresh: false,
    key: Buffer.from(uint8).toString('hex')
  }
}
