import { useEffect, useState, ReactNode, useCallback } from "react"
import { AuthProvider, AuthProviderProps } from "oidc-react"
import axios from "axios"
import Loading from "../Loading"
import AuthOidcConfigContext from "src/contexts/AuthOidcConfigContext"

type ConfigurationData = {
  authority: string
  clientId: string
  scopes: string
  signOutRedirect: string
}

export const checkRedirectVulnerability = (path: string) => {
  try {
    const origin = window.location.origin
    const urlPath = new URL(path)
    const storedUrl = new URL(urlPath, origin)
    // Ensure that the stored path has the same origin as the current location
    if (storedUrl.origin === origin) return true
    if (storedUrl.origin === "https://login.microsoftonline.com") return true

    return false
  } catch (error) {
    console.log(error)
    return false
  }
}

export default function AuthOidcProvider({ children }: { children: ReactNode }): JSX.Element {
  const [oidcConfig, setOidcConfig] = useState<AuthProviderProps>()

  const location = window.location

  const handleConfiguration = useCallback(async () => {
    const { data }: { data: ConfigurationData } = await axios.get(
      "/api/oidc/frontend-configuration"
    )

    if (!data) return

    if (!checkRedirectVulnerability(data.authority)) {
      console.error("Invalid OIDC authority")
      return
    }

    setOidcConfig({
      redirectUri: `${location.origin}`,
      postLogoutRedirectUri: data.signOutRedirect ?? `${location.origin}/account/signout`,
      silentRedirectUri: `${location.origin}/`,
      authority: data.authority,
      clientId: data.clientId,
      scope: data.scopes,
      automaticSilentRenew: true,
      onBeforeSignIn() {
        /* istanbul ignore next */
        localStorage.setItem("stored-path", location.href)
      },
      autoSignIn: true,
      loadUserInfo: false,
      onSignIn() {
        /* istanbul ignore next */
        window.history.replaceState(null, "", " ")

        const storedPath = localStorage.getItem("stored-path")
        if (storedPath && checkRedirectVulnerability(storedPath)) {
          location.href = storedPath

          localStorage.removeItem("stored-path")
        }
      },
      onSignOut() {
        /* istanbul ignore next */
        localStorage.removeItem("stored-path")
      },
    })

    localStorage.setItem("oidc.authority", data.authority)
    localStorage.setItem("oidc.clientId", data.clientId)
  }, [location.origin, location.href])

  useEffect(() => {
    handleConfiguration()
  }, [handleConfiguration])

  if (!oidcConfig) {
    return <Loading />
  }

  return (
    <AuthOidcConfigContext.Provider value={oidcConfig}>
      <AuthProvider {...oidcConfig}>{children}</AuthProvider>
    </AuthOidcConfigContext.Provider>
  )
}
