import { createRoot } from "react-dom/client"

import "./polyfills"
import "./styles/main.less"

import AuthOidcProvider from "./components/AuthOidcProvider"
import AuthBarrier from "./components/AuthBarrier"

import * as pdfjs from 'pdfjs-dist'
import { Root } from "./Root"

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString()

/** Close the current window if the user was redirected from a popup re-authenticate window
 *  1. user is no longer authenticated
 *  2. user is prompted to reauthenticate
 *  3. clicking the prompt opens a new window for the user to log in through
 *  4. after authentication the window will redirect to /authenticated and close the window itself */
if (window.location.pathname.startsWith("/authenticated")) {
  window.open(window.location.href, "_self")?.close()
}

const container = document.getElementById("root")
const root = createRoot(container!)

const app = (
  <AuthOidcProvider>
    <AuthBarrier>
      <Root />
    </AuthBarrier>
  </AuthOidcProvider>
)

root.render(app)
