import { useEffect, useState } from "react"
import { TEncryptionKey } from "./getEncryptionKey"

type EncryptionGateType = ({getEncryptionKey}: {
  getEncryptionKey: () => Promise<TEncryptionKey>,
}) => TEncryptionKey | undefined;

// Generates an encryption Key based on the keyGen function
// The return value adheres to the EncryptionKeyType type, where isFresh indicates whether the key is new
// Before the key is not generated, this component doesn't return anything (hence its name "...Gate")
export const useEncryptionGate: EncryptionGateType = ({
 getEncryptionKey
}) => {
  const [encryptionKey, setEncryptionKey] = useState<TEncryptionKey>({
    isFresh: false,
    key: null,
  });

  const initKey = async () => {
    const {isFresh, key} = await getEncryptionKey();
    setEncryptionKey({isFresh, key});
  }

  useEffect(() => {
    initKey();
  }, []);

  if (!encryptionKey.key) {
    return;
  }
  return encryptionKey
};

export default useEncryptionGate
